import React, { useState } from 'react';
import { supabase } from '../../lib/supabaseClient.js';
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";
import MenuMobile from "../navbar-mobile/menu.js";
import './rezervace-akce-mobile.css';
import './rezervace-akce-desktop.css';

function RezervaceAkce() {
  const [formData, setFormData] = useState({
    jmeno: '',
    prijmeni: '',
    email: '',
    telefon: '',
    pocet_hostu: '',
    termin1: '',
    typ_akce: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Zápis do Supabase tabulky
    const { error } = await supabase
      .from('rezervace_akce')
      .insert([{ ...formData }]);

    if (error) {
      console.error(error);
      alert('Chyba při odesílání rezervace.');
    } else {
      // Odeslání e-mailů přes Edge Function
      try {
        const res = await fetch('https://fohmfcqxeaopovbslcdz.supabase.co/functions/v1/send-reservation-emails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZvaG1mY3F4ZWFvcG92YnNsY2R6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDExNTc0NTIsImV4cCI6MjA1NjczMzQ1Mn0.jOXw_6-wfDaP-_7xMvhjA02fRh8xu84bBJ7sqe_QCXs'
          },
          body: JSON.stringify({
            ...formData,
            typRezervace: 'akce' // nebo 'ubytovani'
          }),
        });

        if (res.ok) {
          alert('Rezervace byla úspěšně odeslána.');
        } else {
          alert('Rezervace byla uložena, ale e-mail se nepodařilo odeslat.');
        }
      } catch (err) {
        console.error(err);
        alert('Rezervace byla uložena, ale e-mail se nepodařilo odeslat.');
      }

      // Vyprázdnění formuláře
      setFormData({
        jmeno: '',
        prijmeni: '',
        email: '',
        telefon: '',
        pocet_hostu: '',
        termin1: '',
        typ_akce: ''
      });
    }
  };

  return (
    <div>
      {/* Mobile */}
      <div className='mobile-vypnuti'>
        <div id="menu-mobile-main">
          <MenuMobile />
        </div>

        <div className='rezervace-body-pozadi'>
          <form onSubmit={handleSubmit}>
            <div className='rezervace-ubytovani-div-1'>
              <h3 className='rezervace-ubytovani-main-text-in'>Rezervace akce</h3>

              <input id="jmeno" type="text" placeholder="Jméno" value={formData.jmeno} onChange={handleChange} required />
              <input id="prijmeni" type="text" placeholder="Příjmení" value={formData.prijmeni} onChange={handleChange} required />
              <input id="email" type="email" placeholder="E-mailová adresa" value={formData.email} onChange={handleChange} required />
              <input id="telefon" type="tel" placeholder="Telefonní číslo" value={formData.telefon} onChange={handleChange} required />
              <input id="pocet_hostu" type="number" placeholder="Počet hostů" value={formData.pocet_hostu} onChange={handleChange} required />
              <input id="termin1" type="date" placeholder="Datum akce" value={formData.termin1} onChange={handleChange} required />
              <textarea id="typ_akce" placeholder="Typ akce nebo poznámky" value={formData.typ_akce} onChange={handleChange} />
              <p className='rezervace-ubytovani-light-text-2'>Osobní údaje budou zpracovány v souladu s platnými zákony.</p>
              <button type="submit" className='rezervace-ubytovani-button-send'>Odeslat rezervaci</button>
            </div>
          </form>
        </div>
      </div>

      {/* Desktop */}
      <div className='desktop-vypnuti'>
        <h3 className='desktop-homepage-h3-main-nadpis'>Hospůdka pod Rablinů</h3>
        <h4 className='desktop-homepage-h4-main-nadpis'>Ta pravá chuť Valašska</h4>
        <h3 className='desktop-homepage-h3-main-telefon'>+420 608 608 101</h3>

        <div className='desktop-navbar'>
          <NavbarDesktop />
        </div>

        <div className='desktop-aktuality-body'>
          <h3 className='desktop-aktuality-text-1'>REZERVACE AKCE</h3>
        </div>

        <div className='rezervace-akce-zvetsovak'></div>

        <form onSubmit={handleSubmit}>
          <div className='rezervace-ubytovani-div-1'>
            <input className='rezervace-akce-jmeno-a-prijmeni-1' id="jmeno" type="text" placeholder="Jméno" value={formData.jmeno} onChange={handleChange} required />
            <input className='rezervace-akce-jmeno-a-prijmeni-2' id="prijmeni" type="text" placeholder="Příjmení" value={formData.prijmeni} onChange={handleChange} required />
            <input className='rezervace-akce-jmeno-a-prijmeni-3' id="email" type="email" placeholder="E-mailová adresa" value={formData.email} onChange={handleChange} required />
            <input className='rezervace-akce-jmeno-a-prijmeni-4' id="telefon" type="tel" placeholder="Telefonní číslo" value={formData.telefon} onChange={handleChange} required />
            <input className='rezervace-akce-jmeno-a-prijmeni-5' id="pocet_hostu" type="number" placeholder="Počet hostů" value={formData.pocet_hostu} onChange={handleChange} required />
            <input className='rezervace-akce-termin1' id="termin1" type="date" placeholder="Datum akce" value={formData.termin1} onChange={handleChange} required />
            <textarea className='rezervace-akce-typ-akce' id="typ_akce" placeholder="Typ akce nebo poznámky" value={formData.typ_akce} onChange={handleChange} />
            <button type="submit" className='rezervace-ubytovani-button-send'>Odeslat rezervaci</button>
          </div>
        </form>

        <Footer />
      </div>
    </div>
  );
}

export default RezervaceAkce;