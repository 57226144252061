import React from 'react';
import "./desktop-denni-nabidka.css";
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";

import { useState, useEffect } from 'react';
import { sanityClient } from '../../lib/sanityClient.ts';

// ikony
import MenuMobile from "../../komponenty/navbar-mobile/menu.js";


// ikony
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGooglePlus } from "react-icons/fa";

import 'animate.css';


interface ObrazekData {
  obrazek?: {
    asset: {
      url: string;
    };
  };
}

function DenniNabidka() {
  const [obrazekURL, setObrazekURL] = useState<string | null>(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "denniNabidka"][0]{ obrazek{asset->{url}} }`)
      .then((data: ObrazekData) => {
        setObrazekURL(data?.obrazek?.asset?.url || null);
      });
  }, []);

  return (
    <div>



      {/* mobile sekce */}
      <div className='mobile-vypnuti'>

          <div id="menu-mobile-main">
            <MenuMobile />
          </div>

{/* navbar */}
<div id='cely-navbar' className='cely-navbar'>
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-1'></img>

          <div className='navar-pozadi'>
            <h1 className='hospudka-pod-rablinu-nazev-1 animate__animated animate__backInLeft'>Hospůdka pod Rablinů</h1>
            <h3 className='hospudka-pod-rablinu-nazev-2 animate__animated animate__backInLeft'>Ta pravá chuť Valašska</h3>
          </div>
      
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-2'></img>

          <div id="menu-mobile-externi">
            <MenuMobile />
          </div>
  </div>
{/* konec navbaru */}

      
<div className='denni-nabidka-mobile-pozadi-1'>


{obrazekURL && (
  <img
    src={obrazekURL}
    className='denni-nabidka-changing-image-1 animate__animated animate__backInLeft animate__delay-0.5s'
  />
)}




</div>



{/*  ------------------------------------------------------------------------------ */}


<img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-fotogalerie-1'></img>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace'>Kontaktní informace</h1>
      <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-3'></img>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Telefon</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-3'>+420 608 608 101</h3>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>E-maily</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-4'>info@hospudkapodrablinu.cz</h3>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-5'>ubytovani@hospudkapodrablinu.cz</h3>
      
      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Sociální sítě</h1>


      <div className="socialni-site-odkazy">
        <a target='blank' href="#"><FaInstagram /></a>
        <a target='blank' href="#"><FaFacebook /></a>
        <a target='blank' href="#"><FaYoutube /></a>
        <a target='blank' href="#"><FaGooglePlus /></a>
      </div>


      <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-6'></img>

      <h3 className='chraneno-autorskými-právy'>© 2020 - 2025 GAMPEIROS s.r.o.</h3>

    
  </div>






  {/* desktop-------------------------------------------------- */}

<div className='desktop-vypnuti'>

<h3 className='desktop-aktuality-h3-main-nadpis'>Hospůdka pod Rablinů</h3>
    <h4 className='desktop-aktuality-h4-main-nadpis'>Ta pravá chuť Valašska</h4>

    <h3 className='desktop-aktuality-h3-main-telefon'>+420 608 608 101</h3>

      
   
    <div className='desktop-navbar'>
        <NavbarDesktop />
    </div>

{/* -------------------------------------------------------- */}

  <div className='desktop-aktuality-body'>

    <h3 className='desktop-aktuality-text-1'>DENNÍ MENU</h3>
    {/* <img src='./img/obrazek-hpr-2.PNG' className='desktop-obrazek-hospudka-pod-rablinu-2'></img> */}

  </div>


  {obrazekURL && (
  <img
    src={obrazekURL}
    className='desktop-denni-nabidka-img'
  />
)}
    <br></br><br></br><br></br><br></br>


    <div className='desktop-aktuality-footer'><Footer /></div>







      </div> {/* konec DESKTOP verze */}
    </div>
    
  );
}

export default DenniNabidka;
