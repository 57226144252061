import React, { useState, useEffect } from 'react';
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import { sanityClient } from '../../lib/sanityClient.ts';
import imageUrlBuilder from '@sanity/image-url';

import MenuMobile from "../../komponenty/navbar-mobile/menu.js";
import { FaInstagram, FaFacebook, FaYoutube, FaGooglePlus } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";

import 'animate.css';

import '../../styles/desktop/balicky/1920x1080.css';
import '../../styles/desktop/balicky/1366x768px.css';
import '../../styles/desktop/balicky/2560x1440px.css';
import '../../styles/mobile/balicky/360x640px.css';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

function Balicky() {

  interface CenikRadek {
    popis: string;
    noci2: string;
    noci3: string;
    noci4: string;
  }
  
  interface BalicekData {
    obrazekMobilni: { asset: { url: string } };
    obrazekDesktop1: { asset: { url: string } };
    obrazekDesktop2: { asset: { url: string } };
    nadpis: string;
    podnadpis: string;
    uvodniText: string;
    obsahBalicku: string[];
    cenik: CenikRadek[];
    dodatek: string;
  }
  
  const [balicek, setBalicek] = useState<BalicekData | null>(null);
  useEffect(() => {
    sanityClient.fetch(`*[_type == "balicek"][0] {
      nadpis,
      podnadpis,
      uvodniText,
      obsahBalicku,
      cenik,
      dodatek,
      obrazekMobilni { asset -> { url } },
      obrazekDesktop1 { asset -> { url } },
      obrazekDesktop2 { asset -> { url } }
    }`).then((data) => setBalicek(data));
  }, []);

  if (!balicek) return null;

  return (
    <div>
      {/* MOBILE */}
      <div className='mobile-vypnuti'>
        <div id="menu-mobile-main">
          <MenuMobile />
        </div>

        <div id='cely-navbar' className='cely-navbar'>
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-1' />
          <div className='navar-pozadi'>
            <h1 className='hospudka-pod-rablinu-nazev-1 animate__animated animate__backInLeft'>Hospůdka pod Rablinů</h1>
            <h3 className='hospudka-pod-rablinu-nazev-2 animate__animated animate__backInLeft'>Ta pravá chuť Valašska</h3>
          </div>
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-2' />
          <div id="menu-mobile-externi">
            <MenuMobile />
          </div>
        </div>

        <h1 className='mobile-balicky-nazev-balicku-main'>{balicek.nadpis}</h1>
        <h4 className='mobile-balicky-nazev-balicku-second'>{balicek.podnadpis}</h4>
        <img src={balicek.obrazekMobilni?.asset?.url} className='mobile-balicky-obrazek-pod-second' />
        <h3 className='mobile-balicky-nazev-balicku-third'>{balicek.uvodniText}</h3>

        <h1 className='mobile-balicky-nazev-balicku-obsah-pobytoveho-balicku'>OBSAH POBYTOVÉHO BALÍČKU</h1>
        {balicek.obsahBalicku?.map((polozka, index) => (
          <h1 key={index} className='mobile-balicky-nazev-balicku-obsah-pobytoveho-balicku3'>{polozka}</h1>
        ))}

        <h1 className='mobile-balicky-nazev-balicku-obsah-pobytoveho-balicku'>CENOVÁ NABÍDKA</h1>
        <div className="mobile-balicky-table">
          {balicek.cenik?.map((radek, index) => (
            <div className="mobile-balicky-row" key={index}>
              <div className="mobile-balicky-label">{radek.popis}</div>
              <div className="mobile-balicky-price"><u>2 noci: </u>{radek.noci2}</div>
              <div className="mobile-balicky-price"><u>3 noci: </u>{radek.noci3}</div>
              <div className="mobile-balicky-price"><u>4 noci: </u>{radek.noci4}</div>
              <br /><br />
            </div>
          ))}
        </div>

        <h1 className='mobile-balicky-nazev-balicku-prijde-sem'>{balicek.dodatek}</h1>
        <div className='mobile-pozadi-bacliky'></div>

        {/* Kontaktni informace */}
        <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-fotogalerie-1' />
        <h1 className='hospudka-pod-rablinu-kontaktni-informace'>Kontaktní informace</h1>
        <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-3' />
        <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Telefon</h1>
        <h3 className='hospudka-pod-rablinu-kontaktni-informace-3'>+420 608 608 101</h3>
        <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>E-maily</h1>
        <h3 className='hospudka-pod-rablinu-kontaktni-informace-4'>info@hospudkapodrablinu.cz</h3>
        <h3 className='hospudka-pod-rablinu-kontaktni-informace-5'>ubytovani@hospudkapodrablinu.cz</h3>
        <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Sociální sítě</h1>
        <div className="socialni-site-odkazy">
          <a target='blank' href="#"><FaInstagram /></a>
          <a target='blank' href="#"><FaFacebook /></a>
          <a target='blank' href="#"><FaYoutube /></a>
          <a target='blank' href="#"><FaGooglePlus /></a>
        </div>
        <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-6' />
        <h3 className='chraneno-autorskými-právy'>© 2020 - 2025 GAMPEIROS s.r.o.</h3>
      </div>

      {/* DESKTOP */}
      <div className='desktop-vypnuti'>
        <h3 className='desktop-homepage-h3-main-nadpis'>Hospůdka pod Rablinů</h3>
        <h4 className='desktop-homepage-h4-main-nadpis'>Ta pravá chuť Valašska</h4>
        <h3 className='desktop-homepage-h3-main-telefon'>+420 608 608 101</h3>

        <div className='desktop-navbar'>
          <NavbarDesktop />
        </div>

        <div className='desktop-balicky-body'>
          <h3 className='desktop-balicky-text-1'>BALÍČKY</h3>
        </div>

        <img src={balicek.obrazekDesktop1?.asset?.url} className='desktop-balicky-fotka-main-1' />
        <h1 className='desktop-balicky-1-text-main'>{balicek.nadpis}</h1>
        <h4 className='desktop-balicky-text-second-1'>{balicek.podnadpis}</h4>
        <h4 className='desktop-balicky-text-second-2'>{balicek.uvodniText}</h4>

        <h1 className='desktop-balicky-obsah-balicku'>Obsah pobytového balíčku:</h1>
        <h4 className='desktop-balicky-obsah-balicku-2'>
          {balicek.obsahBalicku?.map((p, i) => (<div key={i}><FaAngleRight /> {p}<br /></div>))}
        </h4>

        <img src={balicek.obrazekDesktop2?.asset?.url} className='desktop-balicky-fotka-main-2' />
        <img src='./img/hlavni-interier.jpg' className='desktop-balicky-fotka-main-3' />

        <h1 className='desktop-balicky-cena-balicku'>Cenová nabídka:</h1>
        <table className='desktop-balicky-ceny-tabulka'>
          <thead>
            <tr>
              <th>Počet osob</th><th></th><th></th><th>4 noci</th><th>3 noci</th><th>2 noci</th>
            </tr>
          </thead>
          <tbody>
            {balicek.cenik?.map((r, i) => (
              <tr key={i}>
                <td>{r.popis}</td><td></td><td></td><td>{r.noci4}</td><td>{r.noci3}</td><td>{r.noci2}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <p className='desktop-balicky-text-pod-tabulku'>{balicek.dodatek}</p>
        <div className='desktop-balicky-footer'><Footer /></div>
      </div>
    </div>
  );
}

export default Balicky;
