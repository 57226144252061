import React, { useState } from 'react';
import { supabase } from '../../lib/supabaseClient.js';
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";
import MenuMobile from "../navbar-mobile/menu.js";
import './rezervace-ubytovani-mobile.css';
import './rezervace-ubytovani-desktop.css';

function RezervaceUbytovani() {
  const [formData, setFormData] = useState({
    jmeno: '',
    prijmeni: '',
    email: '',
    telefon: '',
    pocet_hostu: '',
    poznamky: '',
    termin1: '',
    termin2: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { error } = await supabase
      .from('rezervace_ubytovani')
      .insert([{ ...formData }]);

    if (error) {
      console.error(error);
      alert('Chyba při odesílání rezervace.');
    } else {
      try {
        const res = await fetch('https://fohmfcqxeaopovbslcdz.supabase.co/functions/v1/send-reservation-emails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZvaG1mY3F4ZWFvcG92YnNsY2R6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDExNTc0NTIsImV4cCI6MjA1NjczMzQ1Mn0.jOXw_6-wfDaP-_7xMvhjA02fRh8xu84bBJ7sqe_QCXs'
          },
          body: JSON.stringify({
            ...formData,
            typRezervace: 'ubytovani' // nebo 'ubytovani'
          }),
        });

        if (res.ok) {
          alert('Rezervace byla úspěšně odeslána.');
        } else {
          alert('Rezervace byla uložena, ale e-mail se nepodařilo odeslat.');
        }
      } catch (err) {
        console.error(err);
        alert('Rezervace byla uložena, ale e-mail se nepodařilo odeslat.');
      }

      setFormData({
        jmeno: '',
        prijmeni: '',
        email: '',
        telefon: '',
        pocet_hostu: '',
        poznamky: '',
        termin1: '',
        termin2: ''
      });
    }
  };

  return (
    <div>
      {/* Mobile */}
      <div className='mobile-vypnuti'>
        <div id="menu-mobile-main">
          <MenuMobile />
        </div>

        <div className='rezervace-body-pozadi'>
          <form onSubmit={handleSubmit}>
            <div className='rezervace-ubytovani-div-1'>
              <h3 className='rezervace-ubytovani-main-text-in'>Rezervace pokoje</h3>

              <input id="jmeno" type="text" placeholder="Jméno" className='rezervace-ubytovani-jmeno-a-prijmeni' value={formData.jmeno} onChange={handleChange} required />
              <input id="prijmeni" type="text" placeholder="Příjmení" className='rezervace-ubytovani-jmeno-a-prijmeni' value={formData.prijmeni} onChange={handleChange} required />
              <input id="email" type="email" placeholder="E-mailová adresa" className='rezervace-ubytovani-jmeno-a-prijmeni' value={formData.email} onChange={handleChange} required />
              <input id="telefon" type="tel" placeholder="Telefonní číslo" className='rezervace-ubytovani-jmeno-a-prijmeni' value={formData.telefon} onChange={handleChange} required />
              <input id="pocet_hostu" type="number" placeholder="Počet hostů" className='rezervace-ubytovani-jmeno-a-prijmeni' value={formData.pocet_hostu} onChange={handleChange} required />
              <input id="termin1" type="date" placeholder="Datum příjezdu" className='rezervace-ubytovani-termin1' value={formData.termin1} onChange={handleChange} required />
              <input id="termin2" type="date" placeholder="Datum odjezdu" className='rezervace-ubytovani-termin1' value={formData.termin2} onChange={handleChange} required />
              <textarea id="poznamky" placeholder="Poznámky k rezervaci" className='rezervace-ubytovani-jmeno-a-prijmeni-2' value={formData.poznamky} onChange={handleChange} />
              <br /><br />
              <p className='rezervace-ubytovani-light-text-2'>Osobní údaje budou zpracovány v souladu s platnými zákony.</p>
              <button type="submit" className='rezervace-ubytovani-button-send'>Odeslat rezervaci</button>
            </div>
          </form>
        </div>
      </div>

      {/* Desktop */}
      <div className='desktop-vypnuti'>
        <h3 className='desktop-homepage-h3-main-nadpis'>Hospůdka pod Rablinů</h3>
        <h4 className='desktop-homepage-h4-main-nadpis'>Ta pravá chuť Valašska</h4>
        <h3 className='desktop-homepage-h3-main-telefon'>+420 608 608 101</h3>
        <div className='desktop-navbar'>
          <NavbarDesktop />
        </div>

        <div className='desktop-aktuality-body'>
          <h3 className='desktop-aktuality-text-1'>REZERVACE UBYTOVÁNÍ</h3>
        </div>

        <form onSubmit={handleSubmit}>
          <div className='rezervace-ubytovani-div-1'>
            <input id="jmeno" type="text" placeholder="Jméno" className='rezervace-ubytovani-jmeno' value={formData.jmeno} onChange={handleChange} required />
            <input id="prijmeni" type="text" placeholder="Příjmení" className='rezervace-ubytovani-jmeno-a-prijmeni' value={formData.prijmeni} onChange={handleChange} required />
            <input id="email" type="email" placeholder="E-mailová adresa" className='rezervace-ubytovani-email' value={formData.email} onChange={handleChange} required />
            <input id="telefon" type="tel" placeholder="Telefonní číslo" className='rezervace-ubytovani-telefon' value={formData.telefon} onChange={handleChange} required />
            <input id="pocet_hostu" type="number" placeholder="Počet hostů" className='rezervace-ubytovani-pocet-hostu' value={formData.pocet_hostu} onChange={handleChange} required />
            <input id="termin1" type="date" placeholder="Datum příjezdu" className='rezervace-ubytovani-termin1' value={formData.termin1} onChange={handleChange} required />
            <input id="termin2" type="date" placeholder="Datum odjezdu" className='rezervace-ubytovani-termin2' value={formData.termin2} onChange={handleChange} required />
            <textarea id="poznamky" placeholder="Poznámky k rezervaci" className='rezervace-ubytovani-poznamky' value={formData.poznamky} onChange={handleChange} />
            <br /><br />
            <div className='desktop-rezervace-ubytovani-div'></div>
            <button type="submit" className='rezervace-ubytovani-button-send'>Odeslat rezervaci</button>
          </div>
        </form>

        <div className='desktop-ubytovani-rezervace-zmensovaci-div'></div>
        <Footer />
      </div>
    </div>
  );
}

export default RezervaceUbytovani;