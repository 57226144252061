import React, { useState, useEffect } from 'react';
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";
import MenuMobile from "../../komponenty/navbar-mobile/menu.js";
import { FaInstagram, FaFacebook, FaYoutube, FaGooglePlus } from "react-icons/fa";
import { sanityClient } from '../../lib/sanityClient.ts';
import 'animate.css';

import '../../styles/desktop/aktuality/1920x1080.css';
import '../../styles/desktop/aktuality/1366x768px.css';
import '../../styles/desktop/aktuality/2560x1440px.css';
import '../../styles/mobile/aktuality/360x640px.css';

interface Aktualita {
  nadpis: string;
  text: string;
  datum?: string;
  obrazek?: {
    asset: {
      url: string;
    };
  };
}

function Aktuality() {
  const [aktuality, setAktuality] = useState<Aktualita[]>([]);

  useEffect(() => {
    sanityClient.fetch(`*[_type == "aktualita"] | order(_createdAt desc)[0...2]{
      nadpis, text, obrazek{asset->{url}}, datum
    }`).then(setAktuality);
  }, []);

  const aktualita1 = aktuality[0];
  const aktualita2 = aktuality[1];

  return (
    <div>
      {/* MOBILE */}
      <div className='mobile-vypnuti'>
        <div id="menu-mobile-main"><MenuMobile /></div>
        <div id='cely-navbar' className='cely-navbar'>
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-1' />
          <div className='navar-pozadi'>
            <h1 className='hospudka-pod-rablinu-nazev-1'>Hospůdka pod Rablinů</h1>
            <h3 className='hospudka-pod-rablinu-nazev-2'>Ta pravá chuť Valašska</h3>
          </div>
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-2' />
          <div id="menu-mobile-externi"><MenuMobile /></div>
        </div>

        {aktualita1 && (
          <div>
            <h1 className='mobile-aktuality-text-main'>{aktualita1.nadpis}</h1>
            <h4 className='mobile-aktuality-text-second'>{aktualita1.text}</h4>
            <p className='mobile-aktuality-datum-akce'>
              <b>Datum akce:</b> {aktualita1.datum || 'neuvedeno'}
            </p>
            <img src={aktualita1.obrazek?.asset?.url} className='mobile-aktuality-img' />
          </div>
        )}

        {aktualita2 && (
          <div>
            <h1 className='mobile-aktuality-text-main2'>{aktualita2.nadpis}</h1>
            <h4 className='mobile-aktuality-text-second2'>{aktualita2.text}</h4>
            <p className='mobile-aktuality-datum-akce2'>
              <b>Datum akce:</b> {aktualita2.datum || 'neuvedeno'}
            </p>
            <img src={aktualita2.obrazek?.asset?.url} className='mobile-aktuality-img2' />
          </div>
        )}

        <div className="socialni-sekce">
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-fotogalerie-1' />
          <h1 className='hospudka-pod-rablinu-kontaktni-informace'>Kontaktní informace</h1>
          <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-3' />
          <h3 className='hospudka-pod-rablinu-kontaktni-informace-3'>+420 608 608 101</h3>
          <h3 className='hospudka-pod-rablinu-kontaktni-informace-4'>info@hospudkapodrablinu.cz</h3>
          <h3 className='hospudka-pod-rablinu-kontaktni-informace-5'>ubytovani@hospudkapodrablinu.cz</h3>
          <div className="socialni-site-odkazy">
            <a target='blank' href="#"><FaInstagram /></a>
            <a target='blank' href="#"><FaFacebook /></a>
            <a target='blank' href="#"><FaYoutube /></a>
            <a target='blank' href="#"><FaGooglePlus /></a>
          </div>
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-6' />
          <h3 className='chraneno-autorskými-právy'>© 2020 - 2025 GAMPEIROS s.r.o.</h3>
        </div>
      </div>

      {/* DESKTOP */}
      <div className='desktop-vypnuti'>
        <h3 className='desktop-aktuality-h3-main-nadpis'>Hospůdka pod Rablinů</h3>
        <h4 className='desktop-aktuality-h4-main-nadpis'>Ta pravá chuť Valašska</h4>
        <h3 className='desktop-aktuality-h3-main-telefon'>+420 608 608 101</h3>

        <div className='desktop-navbar'>
          <NavbarDesktop />
        </div>

        <div className='desktop-aktuality-body'>
          <h3 className='desktop-aktuality-text-1'>AKTUALITY</h3>
        </div>

          {aktualita1 && (
            <div>
              <h1 className='desktop-aktuality-text-main'>{aktualita1.nadpis}</h1>
              <h4 className='desktop-aktuality-text-second'>{aktualita1.text}</h4>
              <p className='desktop-aktuality-datum-akce'>
                <b>Datum akce:</b> {aktualita1.datum || 'neuvedeno'}
              </p>
              <img src={aktualita1.obrazek?.asset?.url} className='desktop-aktuality-img-main' />
            </div>
          )}

          {aktualita2 && (
            <div className="desktop-aktualita-blok">
              <h1 className='desktop-aktuality-text-main2'>{aktualita2.nadpis}</h1>
              <h4 className='desktop-aktuality-text-second2'>{aktualita2.text}</h4>
              <p className='desktop-aktuality-datum-akce2'>
                <b>Datum akce:</b> {aktualita2.datum || 'neuvedeno'}
              </p>
              <img src={aktualita2.obrazek?.asset?.url} className='desktop-aktuality-img-main2' />
            </div>
          )}
        

        <div className='desktop-aktuality-footer'><Footer /></div>
      </div>
    </div>
  );
}

export default Aktuality;
